// noinspection JSUnresolvedVariable

/**
 * @file
 * Functions initialization.
 */

// Functions initialization.
(function ($, window, document, undefined) {
  "use strict";

  // Execute the code once the document is ready.
  $(document).ready(function () {

    // Display the IPv4.
    $('.cc-ip-address').ccHelperSelectorExists(function () {
      $.ccHelperIpGetIpv4().then(function(ip) {
        $('.cc-ip-address').html(ip);
      }).catch(function(error) {
        console.error(error);
      });
    });
  });

  // Execute the code once the window is ready.
  $(window).ready(function () {

    // ...
  });

  // Execute the code once the window is fully loaded.
  $(window).bind('load', function () {

    // ...
  });

  // Execute the code when the window orientation changes.
  $(window).bind('orientationchange', function () {

    // ...
  });

  // Execute the code when the window is scrolled.
  $(window).bind('scroll', function () {

    // ...
  });

  // Execute code when the window is resized.
  $(window).resize(function () {

    // ...
  });

}(jQuery, window, document));

// noinspection JSUndeclaredVariable

/**
 * @file
 * Functions declaration.
 */

// Functions declaration.
(function ($, window, document, undefined) {
  "use strict";

  // ...

}(jQuery, window, document));

// noinspection JSUnresolvedVariable

/**
 * @file
 * Theme Scripts.
 */

// Requires.
require('./theme/_functions.js');
require('./theme/_init.js');
